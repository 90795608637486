import axios from 'axios'
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'

export function loadPrerequisiteData ({dispatch}) {
  return Promise.all([
    dispatch('loadProducts')
  ])
}

export function loadProducts ({commit, rootGetters}) {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

  // retrieve products for this orgUnit only, without inheritance from parent orgUnits
  return smarterGet('/api/v2/org/{orgUnitId}/products', {
    id: 'orgUnit.products.loadPrerequisiteData',
    sharedCache: true,
    inputs: {
      orgUnitId: () => orgUnitId
    },
    onResult (products) {
      // products are not stored in this module
      commit('masterData/setCustomProducts', {orgUnitId, products}, {root: true})
    }
  })
}

export async function saveProduct ({state, rootGetters}) {
  const data = {
    ...state.editBuffer.product,
    orgUnitId: rootGetters['fieldRecordSystem/orgUnitId'],
    source: 'local-product-administration',
    type: state.selectedGroup
  }

  return smarterPost('/api/v2/org/{orgUnitId}/products', data, {
    id: 'orgUnit.products.saveProduct',
    inputs: {
      orgUnitId: () => rootGetters['fieldRecordSystem/orgUnitId']
    }
  })
}

export async function deleteProduct ({commit, dispatch, rootGetters}, productId) {
  const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

  await axios.delete(`/api/v2/org/${orgUnitId}/products/${productId}`)
  await dispatch('loadProducts')

  commit('setSelectedProductId', null)
}

export async function reset ({commit}) {
  commit('reset')
}
