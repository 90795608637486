import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

export const defaults = () => ({
  selectedProductId: null,
  selectedGroup: 'seed' // fertilization, protectant
})

export default {
  namespaced: true,
  state: defaults(),
  getters,
  actions,
  mutations
}
