import {makeSetters} from '@helpers/vuex/mutations'

const mutations = {
  ...makeSetters([
    'selectedProductId',
    'selectedGroup'
  ]),
  reset (state) {
    state.selectedProductId = null
    state.selectedGroup = 'seed'
  }
}

export default mutations
